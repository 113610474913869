/**
 * Encodes Fast Channel iframe URLs to JSON so that we can pass mobile app xumo
 * parameters to local
 * @see https://nbcnewsdigital.atlassian.net/wiki/spaces/CNBCAND/pages/4796186725/Privacy+and+Programmatic+Metadata+in+WebView
 */
export const encodeToJson = () => {
  try {
    if (window.location.search) {
      if (window.location.search !== '?chromeless=true') {
        const xumoUrlParams = JSON.stringify(window.location.search);
        const xumoUrl = xumoUrlParams.replace('?', '').replace('&chromeless=true', '');
        const encodedString = encodeURIComponent(xumoUrl);
        return encodedString;
      }
    }
} catch (error) {
  // eslint-disable-next-line no-console
  console.error('Error encoding url to JSON', error);
}
  return false;
};
